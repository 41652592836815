<template>
  <div
    v-if="contentData"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->

        <b-col cols="12">
          <b-card>
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0 "
            >

              <b-button

                variant="dark"
                size="sm"
                @click="downloadPage"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span class="text-nowrap">{{ $t("Sayfayı İndir") }}</span>
              </b-button>

            </b-col>
            <b-card-header>
              <!-- title and subtitle -->
              <div>
                <b-card-title class="mb-1">
                  {{ contentData.title }}
                </b-card-title>
                <b-card-sub-title>{{ $staticParams.getBlogContentType(contentData.content_type).label }}</b-card-sub-title>
              </div>
              <!--/ title and subtitle -->

              <!-- badge -->
              <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                <h5 class="font-weight-bolder mb-0 mr-1" />

              </div>
              <!--/ badge -->
            </b-card-header>
            <!-- eslint-disable vue/no-v-html -->
            <b-card-body>
              <vue-html2pdf
                ref="html2Pdf"
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="contentData.title"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"

                pdf-content-width="800px"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
              >
                <section slot="pdf-content">
                  <div

                    class="blog-content"
                    v-html="contentData.content"
                  />
                </section>
              </vue-html2pdf>
              <div

                class="blog-content"
                v-html="contentData.content"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <!--/ blogs -->

        <!-- Leave a Blog Comment -->

        <!--/ Leave a Blog Comment -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

  </div>
</template>

<script>
import {

  BCard, BRow, BCol, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import VueHtml2pdf from 'vue-html2pdf'
import blogContentStoreModule from '../blogContentStoreModule'

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardBody,
    BCol,
    VueHtml2pdf,
    BCardTitle,
    BCardSubTitle,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.fetchData()
    },
  },
  methods: {
    downloadPage() {
      this.$refs.html2Pdf.generatePdf()
    },
    dateFormat(date) { return this.$moment(date).fromNow() },
  },
  setup() {
    const contentData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-blog-content'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, blogContentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const fetchData = (() => {
      contentData.value = null
      store.dispatch('app-blog-content/fetchSingleBlogContentForShowing', { id: router.currentRoute.params.id })
        .then(response => {
          contentData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            contentData.value = undefined
          }
        })
    })
    fetchData()
    return {
      contentData,
      fetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
